// extracted by mini-css-extract-plugin
export var body = "GalleryModal-module--body--Go7bT";
export var container = "GalleryModal-module--container--CRYKy";
export var desktop = "GalleryModal-module--desktop--K6hEi";
export var footer = "GalleryModal-module--footer--Wb+wk";
export var header = "GalleryModal-module--header--APiSI";
export var imageContainer = "GalleryModal-module--imageContainer--lEFj3";
export var mobile = "GalleryModal-module--mobile--IXG9J";
export var modal = "GalleryModal-module--modal--9bGdN";
export var officeName = "GalleryModal-module--officeName---bLUG";
export var overlay = "GalleryModal-module--overlay--Dwsol";
export var singleSlide = "GalleryModal-module--singleSlide--Ew8-B";
export var wrapper = "GalleryModal-module--wrapper--0Ogvc";