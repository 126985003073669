export const mapStyles = [
  {
    featureType: 'administrative.country',
    elementType: 'geometry.stroke',
    stylers: [{ color: '#173e70' }],
  },
  { featureType: 'administrative.country', elementType: 'labels', stylers: [{ color: '#ffffff' }] },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#60d2e9' }],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'geometry.stroke',
    stylers: [{ visibility: 'on' }, { color: '#173e70' }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels',
    stylers: [{ color: '#ff0000' }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#60d2e9' }],
  },
  {
    featureType: 'administrative.province',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text',
    stylers: [{ color: '#007087' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#60d2e9' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'on' }, { invert_lightness: true }, { color: '#ffffff' }],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#55a8d9' }],
  },
  {
    featureType: 'administrative.neighborhood',
    elementType: 'labels.text.stroke',
    stylers: [{ visibility: 'off' }],
  },
  { featureType: 'landscape', elementType: 'all', stylers: [{ visibility: 'on' }] },
  { featureType: 'landscape', elementType: 'geometry.fill', stylers: [{ color: '#007087' }] },
  { featureType: 'landscape.man_made', elementType: 'all', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'all',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'landscape.natural.terrain',
    elementType: 'geometry',
    stylers: [{ visibility: 'off' }],
  },
  { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.park', elementType: 'all', stylers: [{ visibility: 'on' }] },
  { featureType: 'poi.park', elementType: 'geometry.fill', stylers: [{ color: '#0472b8' }] },
  { featureType: 'poi.park', elementType: 'labels.text', stylers: [{ color: '#55a8d9' }] },
  { featureType: 'poi.park', elementType: 'labels.text.stroke', stylers: [{ visibility: 'off' }] },
  { featureType: 'poi.park', elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      { visibility: 'on' },
      { lightness: '-53' },
      { invert_lightness: true },
      { color: '#007dc6' },
    ],
  },
  { featureType: 'road', elementType: 'geometry.fill', stylers: [{ color: '#007087' }] },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }, { color: '#007087' }],
  },
  {
    featureType: 'road',
    elementType: 'labels.text',
    stylers: [{ color: '#e9ecd5' }, { visibility: 'off' }],
  },
  { featureType: 'road', elementType: 'labels.text.fill', stylers: [{ color: '#bfa2a2' }] },
  { featureType: 'transit', elementType: 'all', stylers: [{ visibility: 'off' }] },
  { featureType: 'water', elementType: 'geometry.fill', stylers: [{ color: '#0a4753' }] },
  { featureType: 'water', elementType: 'labels.text.fill', stylers: [{ color: '#007087' }] },
];
