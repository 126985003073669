import React, { Component, useRef } from 'react';

// === Components === //
import Helmet from 'react-helmet';
import Header from '@components/Header/Header';
import Introduction from '@components/Introduction/Introduction';
import Map from '@components/Map/Map';

// === Config === //
import { officesList } from '../config/officesList';

// === Types === //
import { IOffice } from '@type/index';
import Gallery from '@components/Gallery/Gallery';
import Footer from '@components/Footer/Footer';
import * as utils from '@utils';
import Plug from '@components/Plug/Plug';

class Index extends Component {
  state = {
    selectedOffice: null,
    zoom: null,
    center: null,
    galleryDefaultOffice: null,
  };

  changeStateValue = (name: string, value: any) => {
    this.setState({ [name]: value });
  };

  handleShowPhotos = (office: IOffice) => {
    utils.useOfficeStore.getState().setOfficeId(office.location);

    if (typeof window !== 'undefined') {
      const gallery: HTMLElement | null = document.getElementById('gallery');
      gallery?.scrollIntoView({ behavior: 'smooth' });

      this.setState({ galleryDefaultOffice: { label: office.location, value: office.id } });
    }
  };

  handleCenterMarker = (office) => {
    const defaultZoom = 3;
    const defaultCenter = { lat: 28, lng: 0 };

    this.setState(
      office
        ? {
            selectedOffice: {
              location: office.name,
              coordinates: {
                lat: Number(office.latitude),
                lng: Number(office.longitude),
              },
              photosCount: Number(office.count),
              id: Number(office.office_id),
            } as IOffice,
            zoom: 13,
            center: {
              lat: Number(office.latitude),
              lng: Number(office.longitude),
            },
          }
        : {
            zoom: defaultZoom,
            center: defaultCenter,
          }
    );
  };

  render() {
    return (
      <div>
        <Helmet title="Foundation Day" />
        <Header />

        {process.env.PLUG_VISIBLE === 'true' ? (
          <Plug />
        ) : (
          <>
            <Introduction page="home" />
            <Map
              zoom={this.state.zoom}
              center={this.state.center}
              selectedOffice={this.state.selectedOffice}
              changeStateValue={this.changeStateValue}
              handleShowPhotos={this.handleShowPhotos}
            />
            <Gallery
              defaultOffice={this.state.galleryDefaultOffice}
              handleCenterMarker={this.handleCenterMarker}
            />
          </>
        )}

        <Footer />
      </div>
    );
  }
}

export default Index;
