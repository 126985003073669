import Header from '@components/Header/Header';
import Introduction from '@components/Introduction/Introduction';
import React from 'react';

function Plug() {
  return (
    <>
      <Introduction plug page="home" />
    </>
  );
}

export default Plug;
