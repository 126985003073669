import React from 'react';
import { IGalleryModal } from '@components/GalleryModal/GalleryModal.d';
import * as styles from '@components/GalleryModal/GalleryModal.module.scss';
import closeButton from '@assets/images/close-button.svg';
import downloadIcon from '@assets/images/download-icon.svg';
import cn from 'classnames';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Keyboard, Navigation } from 'swiper';
import { BASIC_SERVER_URL } from '../../lib/constants';

const GalleryModal = ({ closeModal, src, data, modalIndex }: IGalleryModal) => {
  return (
    <>
      <div className={styles.overlay} onClick={closeModal}></div>
      <div className={styles.modal}>
        <div className={styles.container}>
          <div className={styles.header}>
            <button onClick={closeModal}>
              <img src={closeButton} alt="" />
            </button>
          </div>

          <div className={styles.body}>
            <div className={styles.desktop}>
              <div className={styles.wrapper}>
                <Swiper
                  initialSlide={modalIndex}
                  navigation={true}
                  keyboard={{
                    enabled: true,
                  }}
                  modules={[Keyboard, Navigation]}
                  className="mySwiper"
                >
                  {data.map((item, index) => (
                    <SwiperSlide key={index}>
                      <div className={styles.singleSlide}>
                        <picture>
                          <img src={`${BASIC_SERVER_URL}${item.big}`} alt="" />
                        </picture>
                        <div className={styles.officeName}>{item.office.name}</div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>

            <div className={styles.mobile}>
              <Swiper
                initialSlide={modalIndex}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {data.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className={cn(styles.imageContainer, styles.singleSlide)}>
                      <picture>
                        <img src={`${BASIC_SERVER_URL}${item.big}`} alt="" />
                      </picture>
                      <div className={styles.officeName}>{item.office.name}</div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>

          <div className={styles.footer}>
            <a download href={src} target="_blank">
              <img src={downloadIcon} alt="" />
              <span>Download</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryModal;
