import React, { useEffect, useState } from 'react';

// === Assets === //
import pinActive from '@assets/images/pin-activ.svg';
import pinNoActive from '@assets/images/pin-inactive.svg';

// === Components === //
import {
  GoogleMap,
  GoogleMapProps,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api';

// === Config === //
import { mapStyles } from '../../config/mapStyles';

// === Helpers === //
import axios from 'axios';
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/Map/Map.module.scss';

// === Types === //
import { IMap } from '@components/Map/Map.d';
import { IOffice } from '@type/index';

const containerStyle = {
  width: '100%',
  height: 'inherit',
};

const Map = ({ zoom, center, selectedOffice, changeStateValue, handleShowPhotos }: IMap) => {
  const [markers, setMarkers] = useState<IOffice[]>([]);
  const [map, setMap] = useState<GoogleMapProps | null>(null);
  const defaultZoom = 3;
  const defaultCenter = { lat: 28, lng: 0 };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: `${process.env.GOOGLE_MAP_API_KEY}`,
  });

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.API_URL}/api/offices`)
      .then((response) => {
        if (response && response.status === 200) {
          setMarkers(
            response.data.map((item) => ({
              location: item.name,
              coordinates: {
                lat: Number(item.latitude),
                lng: Number(item.longitude),
              },
              photosCount: Number(item.count),
              id: Number(item.office_id),
            }))
          );
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  }, []);

  return (
    <div>
      {isLoaded && (
        <div className={styles.mapInner}>
          <GoogleMap
            mapContainerStyle={containerStyle}
            options={{
              styles: mapStyles,
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
            }}
            center={center || map?.center || defaultCenter}
            zoom={zoom || map?.zoom || defaultZoom}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <MarkerClusterer
              averageCenter={true}
              enableRetinaIcons={true}
              gridSize={60}
              options={{
                imagePath: 'images/agregated',
              }}
              styles={[
                {
                  textColor: '#ffffff',
                  height: 53,
                  width: 53,
                  url: 'images/agregated1.png',
                  fontFamily: 'SnowflakeSansBold, sans-serif',
                  fontWeight: '600',
                  textSize: 14,
                  anchorText: [1, 0],
                },
              ]}
            >
              {(clusterer) =>
                (markers as any).map((office: IOffice, index: number) => (
                  <Marker
                    key={index}
                    position={office.coordinates}
                    clusterer={clusterer}
                    icon={selectedOffice?.id === office.id ? pinActive : pinNoActive}
                    onClick={() => {
                      changeStateValue(
                        'selectedOffice',
                        selectedOffice?.id !== office.id ? office : null
                      );
                      changeStateValue('zoom', null);
                      changeStateValue('center', null);
                    }}
                  >
                    {selectedOffice?.id === office.id && (
                      <InfoWindow options={{}}>
                        <div className={styles.infoWindowWrapper}>
                          <div className={styles.infoWindowText}>
                            <span>{office.location}</span>
                            <span>{office.photosCount || 0} photos</span>
                          </div>
                          <div
                            className={cn(
                              styles.infoWindowButton,
                              (!office.photosCount || office.photosCount === 0) &&
                                styles.infoWindowButtonDisabled
                            )}
                          >
                            <button
                              onClick={() => handleShowPhotos(office)}
                              disabled={!office.photosCount || office.photosCount === 0}
                            >
                              See photos
                            </button>
                          </div>
                          {/* <img
                            src={pinActive}
                            alt=""
                            className={styles.infoWindowPin}
                            onClick={() => changeStateValue('selectedOffice', null)}
                          /> */}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))
              }
            </MarkerClusterer>
          </GoogleMap>
        </div>
      )}
    </div>
  );
};

export default Map;
