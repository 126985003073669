import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import cn from 'classnames';

// === Styles === //
import * as styles from '@components/Gallery/Gallery.module.scss';

import GalleryModal from '@components/GalleryModal/GalleryModal';
import Button from '@components/Button/Button';
import Select from '@components/Select/Select';
import magnifierIcon from '@assets/images/magnifier.svg';
import SimpleLoader from '@components/SimpleLoader/SimpleLoader';
import { useOfficeStore } from '@utils';
import { API_SERVER_URL, BASIC_SERVER_URL } from '../../lib/constants';
import { IGallery, IOffices } from '@components/Gallery/Gallery.d';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

// import required modules
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar } from 'swiper';
import { ISelectOption, ISelectOptionGrouped } from '@components/Select/Select.d';
import EXIF from 'exif-js';

const OFFICES_DEFAULT = { label: 'All offices', value: '' };
const CHARITY_DEFAULT = { label: 'All causes', value: '' };

const fetchClient = axios.create({
  baseURL: API_SERVER_URL,
});

const Gallery = ({ defaultOffice, handleCenterMarker }: IGallery) => {
  const pageRef = useRef(0);
  const officeIdRef = useRef('');
  const charityIdRef = useRef('');
  const firstRenderRef = useRef(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState<number | null>(null);
  const [offices, setOffices] = useState<IOffices[] | any>([]);
  const [charitableFocusArea, setCharitableFocusArea] = useState<any[]>([]);
  const [gallery, setGallery] = useState<any[]>([]);
  const [hasMorePages, setHasMorePages] = useState(false);
  const [loading, setLoading] = useState(true);
  const officeId = useOfficeStore(
    (state) => offices.find((office) => office.label === state.officeId)?.value
  );

  const openModal = (index) => {
    setIsModalOpen(true);
    setModalIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalIndex(null);
  };

  const fetchGallery = async () => {
    const page = pageRef.current;

    setLoading(true);

    try {
      const response = await fetchClient.get('/gallery', {
        params: {
          page,
          office_reference_target_id: officeIdRef.current,
          charitable_focus_area_ref_target_id: charityIdRef.current,
        },
      });

      if (response.status !== 200) return;

      setLoading(false);
      setHasMorePages(page < response?.data?.pager?.total_pages - 1);
      ////////////////////////
      // console.log(response?.data?.rows[0]);
      // const fileUrl = `${BASIC_SERVER_URL}${response?.data?.rows[0].small}`;

      // const base64Encode = (url) => {
      //   const re = new RegExp('.(gif|jpg|jpeg|tiff|png|ico)$', 'i');
      //   let name = /[^(/|\\)]*$/.exec(url)[0];
      //   let type = re.test(name) ? re.exec(name)[0].replace('.', '') : 'jpg';

      //   return new Promise((resolve, reject) => {
      //     let image = new Image();

      //     image.onload = function (event) {
      //       let canvas = document.createElement('canvas');
      //       // draw canvas
      //       canvas.width = image.naturalWidth;
      //       canvas.height = image.naturalHeight;
      //       canvas.getContext('2d').drawImage(image, 0, 0);

      //       let dataUrl = canvas.toDataURL('image/' + type);
      //       resolve({
      //         name: name,
      //         type: type,
      //         dataUrl: dataUrl.split(',')[1],
      //       });
      //     };
      //     image.crossOrigin = 'anonymous';
      //     image.src = url;
      //   });
      // };

      // const toBlob = (url) => {
      //   return new Promise((resolve, reject) => {
      //     base64Encode(url).then((res) => {
      //       let byteString = atob(res.dataUrl);
      //       let ab = new ArrayBuffer(byteString.length);
      //       let ia = new Uint8Array(ab);

      //       for (let i = 0; i < byteString.length; i++) {
      //         ia[i] = byteString.charCodeAt(i);
      //       }

      //       // write the array buffer to blob
      //       let blob = new Blob([ab], { type: 'image/' + res.type });

      //       let formData = new FormData();
      //       formData.append('file', blob, res.name);
      //       resolve(formData.get('file'));
      //     });
      //   });
      // };

      // const blob = toBlob(fileUrl);

      // const file = new File([blob], 'image.jpg', { type: blob.type });
      // console.log(file);

      // EXIF.getData(file, function () {
      //   let exifData = EXIF.pretty(file);
      //   if (exifData) {
      //     console.log(exifData);
      //     console.log(EXIF.getTag(file, 'Orientation'));
      //   }
      // });

      ////////////////////////
      if (page === 0) {
        setGallery(response?.data?.rows);
      }

      if (page > 0) {
        setGallery((current) => [...current, ...response?.data?.rows]);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const fetchOffices = () => {
    fetchClient
      .get('/offices')
      .then((response) => {
        if (response && response.status === 200) {
          const regionsList = [...new Set([...response.data.map((item) => item.region)])];

          setOffices([
            OFFICES_DEFAULT,
            ...regionsList
              .map((region) => ({
                label: region,
                options: [
                  ...response.data
                    .filter((item) => item.region === region)
                    .map((filteredItem) => ({
                      label: filteredItem.name,
                      value: filteredItem.office_id,
                      office: filteredItem,
                    })),
                ].sort((a, b) =>
                  a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim())
                ),
              }))
              .sort((a, b) =>
                a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim())
              ),
          ]);
        }
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  const fetchCauses = () => {
    fetchClient
      .get('/charitable-focus-area')
      .then((response) => {
        if (response && response.status === 200) {
          setCharitableFocusArea([
            CHARITY_DEFAULT,
            ...response.data
              .map((item) => ({
                label: item.name,
                value: item.id,
              }))
              .sort((a, b) =>
                a.label.toLowerCase().trim().localeCompare(b.label.toLowerCase().trim())
              ),
          ]);
        }
      })
      .catch((error) => console.error(`Error: ${error}`));
  };

  useEffect(() => {
    fetchGallery();
    fetchOffices();
    fetchCauses();
  }, []);

  useEffect(() => {
    if (!officeId) return;
    officeIdRef.current = officeId;
    fetchGallery();
  }, [officeId]);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    if (!defaultOffice?.value) return;

    pageRef.current = 0;
    officeIdRef.current = defaultOffice?.value;
    fetchGallery();
  }, [defaultOffice]);

  return (
    <>
      <section className={styles.gallery} id="gallery">
        <div className={cn('page-wrapper')}>
          <div className={styles.filters}>
            <Select
              key={defaultOffice?.value}
              name="focusArea"
              label=""
              options={offices}
              onChange={(option) => {
                pageRef.current = 0;
                officeIdRef.current = option.value;
                handleCenterMarker(option.office);
                fetchGallery();
              }}
              isFilter
              defaultValue={
                defaultOffice
                  ? defaultOffice
                  : { label: OFFICES_DEFAULT.label, value: OFFICES_DEFAULT.value }
              }
            />
            <Select
              name="focusArea"
              label=""
              options={charitableFocusArea}
              onChange={(option) => {
                pageRef.current = 0;
                charityIdRef.current = option.value;
                fetchGallery();
              }}
              isFilter
              defaultValue={{ label: CHARITY_DEFAULT.label, value: CHARITY_DEFAULT.value }}
            />
          </div>

          <div className={styles.grid}>
            {!!gallery.length && (
              <>
                {gallery.map((item, index) => (
                  <div className={styles.item} key={index} onClick={() => openModal(index)}>
                    <span className={styles.loopIcon}>
                      <img src={magnifierIcon} alt="" />
                    </span>
                    <picture>
                      <img
                        className={styles.imageSmall}
                        src={`${BASIC_SERVER_URL}${item.small}`}
                        alt=""
                      />
                    </picture>
                  </div>
                ))}
              </>
            )}
          </div>

          {loading && <SimpleLoader isOnlyDesktop />}

          <div className={styles.loadMore}>
            {hasMorePages && (
              <Button
                handleClick={() => {
                  pageRef.current = pageRef.current + 1;
                  fetchGallery();
                }}
                type="button"
                variant="light"
              >
                Load more
              </Button>
            )}
          </div>

          <div className={styles.slider}>
            {gallery.map((item, index) => (
              <div className={styles.imageContainer} key={index} onClick={() => openModal(index)}>
                <picture>
                  <img
                    className={styles.sliderImage}
                    src={`${BASIC_SERVER_URL}${item.small}`}
                    alt={'alt'}
                  />
                </picture>
              </div>
            ))}
          </div>
        </div>
      </section>
      {isModalOpen && (
        <GalleryModal
          closeModal={closeModal}
          src={modalIndex !== null ? `${BASIC_SERVER_URL}${gallery[modalIndex].big}` : ''}
          data={gallery.map((item) => ({
            ...item,
            office: offices
              .map((officeGroup) => officeGroup.options)
              .flat()
              .find((office) => office?.office?.office_id === item.office_id)?.office,
          }))}
          modalIndex={modalIndex !== null ? modalIndex : undefined}
        />
      )}
    </>
  );
};

export default Gallery;
